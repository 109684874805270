<template>
  <page-view :title="title">
    <div class="user_header_wrapper">
      <a-row :gutter="48" class="row">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input placeholder="用户ID" class="filter_input" v-model.trim="queryParam.id" />
          </div>
        </a-col>

        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>登录名</span>
            <a-input placeholder="登录名" class="filter_input" v-model.trim="queryParam.username" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>手机号</span>
            <a-input placeholder="手机号" class="filter_input" v-model.trim="queryParam.mobile" />
          </div>
        </a-col>
        <a-col :lg="3" :md="12" :sm="24" :style="{ paddingLeft: '0', paddingRight: '0' }">
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="
                () => {
                  $refs.table.refresh(false);
                }
              "
              >查询</a-button
            >
            <a-button @click="resetQuery">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="user_table_wrapper">
      <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <template slot="logo" slot-scope="text">
          <img :src="text" style="width: 45px;height: 45px;" />
        </template>
        <template slot="id" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span class="uid_fat" @mouseover="handleMouseover(record)" @mouseout="handleMouseout(record)" @click="copyInfo(record.id)">
              {{ text.substring(0, 4) + '……' + text.substring(text.split('').length - 4) }}
              <span class="uid_child" v-show="record.showid">{{ text }}</span>
            </span>
            <input type="text" class="input" />
          </a-tooltip>
        </template>
        <template slot="operation" slot-scope="text, scope">
          <a-button v-if="scope.blocked" style="left: 10px;" @click="blocked(scope)">解封</a-button>
          <a-button v-else style="left: 10px;" type="primary" @click="update(scope)">封号</a-button>
        </template>
      </s-table>
    </div>
    <a-modal
      title="封号"
      :width="640"
      :visible="edit.show"
      v-if="edit.show"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <a-input placeholder="原因" v-model="edit.remark" />
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from '@/components';
import { PageView } from '@/layouts';
import { sipUserList, sipRoleUpdateUser, sipUpdate, billAdminList } from '@api';
import { formatDate, roleCodeToName } from '@/utils/util';
import {sipUserUpdate} from "@/api";

const rolesCustomRender = (value, row, index) => {
  let roleNames = '';
  value.map((it) => {
    roleNames += roleCodeToName[it].name + ';';
  });
  return roleNames;
};

export default {
  data() {
    return {
      rolesList: [],
      edit: { show: false, id: 0, remark: '', blocked: false, mobile:'', email:'', username:''  },
      queryParam: {},
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        },
        {
          title: '封号原因',
          dataIndex: 'remark',
          customRender: (text) => (text || '-')
        },
        {
          title: '登录名',
          dataIndex: 'username'
        },
        {
          title: '手机号',
          dataIndex: 'mobile'
        },
        {
          title: '角色',
          dataIndex: 'roles',
          width: '10%',
          customRender: rolesCustomRender
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate,
          sorter: (a, b) => a.createTime - b.createTime
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          customRender: formatDate
        },
        {
          title: '最后登录时间',
          dataIndex: 'lastLoginTime',
          customRender: formatDate
        },
        {
          title: '操作',
          dataIndex: 'registerType',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: (parameter) => {
        return sipUserList(Object.assign(parameter, this.queryParam)).then((result) => {
          result.data.list.forEach((item) => (item.showid = false));
          return result.data;
        });
      }
    };
  },
  created() {
    //修改角色列表
    const array = [];
    const roleObj = {
      FORWARDER: { name: '代发', id: 3 },
      OEM: { name: '贴牌', id: 5 }
    };
    for (let key in roleObj) {
      array.push({ name: roleObj[key].name, value: key });
    }
    this.rolesList = array;
  },
  components: {
    PageView,
    STable
  },
  methods: {
    blocked(edit) {
      sipUserUpdate({
        id: edit.id,
        username: edit.username,
        mobile: edit.mobile,
        email: edit.email,
        remark: edit.remark,
        blocked: !edit.blocked
      }).then((res) => {
        if (res.success) {
          edit.show = false;
          this.$message.success('成功');
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleOk () {
      const edit = this.edit;
      this.blocked(edit);
    },
    handleMouseover(record) {
      record.showid = true;
    },
    handleMouseout(record) {
      record.showid = false;
    },
    // 重置
    resetQuery() {
      this.queryParam = {};
      this.$refs.table.refresh(false);
    },

    update(record) {
      record.show = true;
      this.edit = JSON.parse(JSON.stringify(record));
    },

    handleCancel() {
      this.edit = { show: false };
    },
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.user_header_wrapper {
  padding: 24px 56px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  .row {
    margin-left: 0 !important;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
}
.user_table_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: PingFang SC;
  padding: 24px;
}
.input {
  opacity: 0;
  width: 10px;
}

.input_group {
  display: flex;
  margin-bottom: 24px;
  // align-items: center;
  span {
    margin-top: 5px;
    width: 88px;
    text-align: right;
  }
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
.top5 {
  width: 320px;
  height: 32px;
}
.pagin {
  margin-top: 24px;
  margin-right: 24px;
  text-align: right;
}
</style>
